import React from "react"
import { Element } from 'react-scroll'

const KicksLayerSelectItem = ({
    activeKey,
    layerKey,
    layerName,
    onSelect = () => {},
}) => {
    return (
        <Element
            name={`scroll-target--${layerKey}`}
            onClick={() => onSelect(layerKey)}
            className={`layer-select-item overflow-hidden cursor-pointer mr-4 rounded-lg inline-flex w-44 md:w-52 h-32 md:h-36 flex-col items-center justify-center ${activeKey === layerKey ? 'bg-secondary-600 text-neutral-100' : 'bg-secondary-800 text-neutral-500'}`}>

            <img
                className='layer-select-item-image w-full h-auto'
                style={{ minHeight: 90 }}
                src={`/assets/png/kicks-guide-icons/${layerKey}.png`}
                alt=""
            />
            <p className='text-sm md:text-md w-full text-center py-2 bg-darken'>{layerName}</p>
        </Element>
    )
}

export default KicksLayerSelectItem

