import React, { useEffect } from "react"

import Samples from '../config/kicks-sample-showcase'
import { deepClone  } from '../utils'

const KicksSampleSelect = ({
    onStyleChange = () => {},
}) => {
    return (
        <ul className='sample-select list-style-none px-6 pb-6 grid gap-3 grid-cols-2 xs:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-8'>
            {Samples.map(sample => (
                <li
                    key={sample.key}
                    className={`sample-select-item relative cursor-pointer rounded-lg overflow-hidden h-auto flex flex-col`}
                    onClick={() => onStyleChange(deepClone(sample.style))}>

                    <img className='sample-select-item-image w-full h-auto' style={{ minHeight: 70 }} src={sample.thumbnail} alt={sample.name} />
                    <span className='flex-none w-full py-1 bg-secondary-600 text-white uppercase text-sm text-center z-10 opacity-80'>{sample.name}</span>
                </li>
            ))}
        </ul>
    )
}

export default KicksSampleSelect

