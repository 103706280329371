import {
    THEME_LIGHT,
    THEME_DARK,
    THEME_DOODLES,
    THEME_ELECTRIC_PURPLE,
    THEME_ON_FIRE,
    THEME_NATURE,
    THEME_PINK_PANTHER,
    THEME_VINTAGE,
    THEME_SPACEPOD,
    THEME_HUXLEY,
} from './kicks-sample-themes'

export default [
    {
        key: 'light',
        name: 'Light',
        style: THEME_LIGHT,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-light.png',
    },
    {
        key: 'dark',
        name: 'Dark',
        style: THEME_DARK,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-dark.png',
    },
    // {
    //     key: 'blank',
    //     name: 'Drawing Template',
    //     style: THEME_BLANK,
    //     thumbnail: '/assets/png/kicks-example-thumbnails/theme-blank.png',
    // },
    {
        key: 'electric-purple',
        name: 'Electric Purple',
        style: THEME_ELECTRIC_PURPLE,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-electric-purple.png',
    },
    {
        key: 'on-fire',
        name: 'On Fire',
        style: THEME_ON_FIRE,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-on-fire.png',
    },
    {
        key: 'nature',
        name: 'Nature',
        style: THEME_NATURE,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-nature.png',
    },
    {
        key: 'pink-panther',
        name: 'Pink Panther',
        style: THEME_PINK_PANTHER,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-pink-panther.png',
    },
    {
        key: 'vintage',
        name: 'Vintage',
        style: THEME_VINTAGE,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-vintage.png',
    },
    {
        key: 'spacepod',
        name: 'Space Pod',
        style: THEME_SPACEPOD,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-spacepod.png',
    },
    {
        key: 'doodles',
        name: 'Doodles',
        style: THEME_DOODLES,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-doodles.png',
    },
    {
        key: 'huxley-1',
        name: 'Huxley #1',
        style: THEME_HUXLEY,
        thumbnail: '/assets/png/kicks-example-thumbnails/theme-huxley.png',
    },
]