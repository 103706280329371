import React, { useEffect, useState, useLayoutEffect, useRef } from "react"

import getKicksSVG from '../config/kicks-svg'

const KicksCanvas = ({
    config = null,
    onSelect = () => {},
}) => {
    return (
        <div
            onClick={event => {
                const newSelection = event.target.getAttribute('data-key')
                if (newSelection) {
                    onSelect(newSelection)
                }
            }}
            dangerouslySetInnerHTML={{ __html: getKicksSVG(config) }}
        />
    )
}

export default KicksCanvas

