export const THEME_LIGHT = {
    'background':{
        color: '#ccc'
    },
    'upper-inner':{
        color: '#e0e0e0',
        shadow: 0.33,
        highlight: 0.77
    },
    'lining':{
        color: '#fafafa',
        shadow: 0.14,
        highlight: 1
    },
    'upper-outer':{
        color: '#e0e0e0',
        shadow: 0.33,
        highlight: 0.77
    },
    'upper-outer-frame':{
        color: '#eaeaea',
        shadow: 0.26,
        highlight: 0.84
    },
    'swoosh':{
        color: '#2b2b2b',
        shadow: 1,
        highlight: 0.1
    },
    'swoosh-frame':{
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'laces':{
        color: '#444444',
        shadow: 0.95,
        highlight: 0.13
    },
    'module-back-outer':{
        color: '#eeeeee',
        shadow: 0.23,
        highlight: 0.87
    },
    'module-back-inner':{
        color: '#f1f1f1',
        shadow: 0.21,
        highlight: 0.89
    },
    'module-back-inner-stripes':{
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'module-top':{
        color: '#eeeeee',
        shadow: 0.23,
        highlight: 0.87
    },
    'module-top-blade':{
        color: '#444',
        shadow: 0.95,
        highlight: 0.13
    },
    'module-top-triangle':{
        color: '#444',
        shadow: 0.95,
        highlight: 0.13
    },
    'midsole':{
        color: '#eeeeee',
        shadow: 0.23,
        highlight: 0.87
    },
    'midsole-blade':{
        color: '#444444',
        shadow: 0.95,
        highlight: 0.13
    },
    'midsole-module':{
        color: '#efefef',
        shadow: 0.22,
        highlight: 0.87
    },
    'midsole-module-text':{
        color: '#444444',
        shadow: 0.95,
        highlight: 0.13
    },
    'outsole':{
        color: '#eeeeee',
        shadow: 0.23,
        highlight: 0.87
    }
}

export const THEME_DARK = {
    'background': {
        color: '#232323'
    },
    'upper-inner': {
        color: '#0e0e0e',
        shadow: 1,
        highlight: 0.08
    },
    'lining': {
        color: '#000000',
        shadow: 1,
        highlight: 0.08
    },
    'upper-outer': {
        color: '#0e0e0e',
        shadow: 1,
        highlight: 0.08
    },
    'upper-outer-frame': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    },
    'swoosh': {
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'swoosh-frame': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    },
    'laces': {
        color: '#000000',
        shadow: 1,
        highlight: 0.08
    },
    'module-back-outer': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    },
    'module-back-inner': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    },
    'module-back-inner-stripes': {
        color: '#000000',
        shadow: 1,
        highlight: 0.08
    },
    'module-top': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    },
    'module-top-blade': {
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'module-top-triangle': {
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'midsole': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    },
    'midsole-blade': {
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'midsole-module': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    },
    'midsole-module-text': {
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'outsole': {
        color: '#060606',
        shadow: 1,
        highlight: 0.08
    }
}

export const THEME_DOODLES = {
    background: {
        color: '#7085CE',
        shadow: 0.78,
        highlight: 0.31
    },
    'upper-inner': {
        color: '#FFC2DF',
        shadow: 0.41,
        highlight: 0.68
    },
    lining: {
        color: '#A4A4F4',
        shadow: 0.63,
        highlight: 0.46
    },
    'upper-outer': {
        color: '#99E2FF',
        shadow: 0.38,
        highlight: 0.71
    },
    'upper-outer-frame': {
        color: '#A4A4F4',
        shadow: 0.63,
        highlight: 0.46
    },
    swoosh: {
        color: '#FFE780',
        shadow: 0.28,
        highlight: 0.81
    },
    'swoosh-frame': {
        color: '#696AC6',
        shadow: 0.84,
        highlight: 0.24
    },
    laces: {
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    'module-back-outer': {
        color: '#696AC6',
        shadow: 0.84,
        highlight: 0.24
    },
    'module-back-inner': {
        color: '#A4A4F4',
        shadow: 0.63,
        highlight: 0.46
    },
    'module-back-inner-stripes': {
        color: '#95F1C5',
        shadow: 0.34,
        highlight: 0.75
    },
    'module-top': {
        color: '#696AC6',
        shadow: 0.84,
        highlight: 0.24
    },
    'module-top-blade': {
        color: '#95F1C5',
        shadow: 0.34,
        highlight: 0.75
    },
    'module-top-triangle': {
        color: '#95F1C5',
        shadow: 0.34,
        highlight: 0.75
    },
    midsole: {
        color: '#696AC6',
        shadow: 0.84,
        highlight: 0.24
    },
    'midsole-blade': {
        color: '#95F1C5',
        shadow: 0.34,
        highlight: 0.75
    },
    'midsole-module': {
        color: '#696AC6',
        shadow: 0.84,
        highlight: 0.24
    },
    'midsole-module-text': {
        color: '#ffffff',
        shadow: 0.1,
        highlight: 1
    },
    outsole: {
        color: '#A4A4F4',
        shadow: 0.63,
        highlight: 0.46
    }
}

export const THEME_ELECTRIC_PURPLE = {
    'background':{
        color: '#361299',
        shadow: 0.96,
        highlight: 0.11
    },
    'upper-inner':{
        color: '#AD8EFF',
        shadow: 0.68,
        highlight: 0.41
    },
    'lining':{
        color: '#7B4BFF',
        shadow: 0.85,
        highlight: 0.23
    },
    'upper-outer':{
        color: '#8C61FF',
        shadow: 0.81,
        highlight: 0.28
    },
    'upper-outer-frame':{
        color: '#6B34FF',
        shadow: 0.88,
        highlight: 0.2
    },
    'swoosh':{
        color: '#EEE8FF',
        shadow: 0.25,
        highlight: 0.84
    },
    'swoosh-frame':{
        color: '#5b1eff',
        shadow: 0.9,
        highlight: 0.18
    },
    'laces':{
        color: '#EEE8FF',
        shadow: 0.25,
        highlight: 0.84
    },
    'module-back-outer':{
        color: '#7B4BFF',
        shadow: 0.85,
        highlight: 0.23
    },
    'module-back-inner':{
        color: '#5b1eff',
        shadow: 0.9,
        highlight: 0.18
    },
    'module-back-inner-stripes':{
        color: '#EEE8FF',
        shadow: 0.25,
        highlight: 0.84
    },
    'module-top':{
        color: '#5b1eff',
        shadow: 0.9,
        highlight: 0.18
    },
    'module-top-blade':{
        color: '#EEE8FF',
        shadow: 0.25,
        highlight: 0.84
    },
    'module-top-triangle':{
        color: '#EEE8FF',
        shadow: 0.25,
        highlight: 0.84
    },
    'midsole':{
        color: '#5b1eff',
        shadow: 0.9,
        highlight: 0.18
    },
    'midsole-blade':{
        color: '#EEE8FF',
        shadow: 0.25,
        highlight: 0.84
    },
    'midsole-module':{
        color: '#7B4BFF',
        shadow: 0.85,
        highlight: 0.23
    },
    'midsole-module-text':{
        color: '#EEE8FF',
        shadow: 0.25,
        highlight: 0.84
    },
    'outsole':{
        color: '#7B4BFF',
        shadow: 0.85,
        highlight: 0.23
    }
}

export const THEME_ON_FIRE = {
    "background":{
        color: "#8e1c1c",
    },
    "upper-inner":{
        color: "#ff5a00",
        shadow: 0.74,
        highlight: 0.34
    },
    "lining":{
        color: "#ff9a00",
        shadow: 0.6,
        highlight: 0.49
    },
    "upper-outer":{
        color: "#ff9a00",
        shadow: 0.6,
        highlight: 0.49
    },
    "upper-outer-frame":{
        color: "#ff5a00",
        shadow: 0.74,
        highlight: 0.34
    },
    "swoosh":{
        color: "#ff0000",
        shadow: 0.81,
        highlight: 0.27
    },
    "swoosh-frame":{
        color: "#ffe808",
        shadow: 0.29,
        highlight: 0.8
    },
    "laces":{
        color: "#ffe808",
        shadow: 0.29,
        highlight: 0.8
    },
    "module-back-outer":{
        color: "#ff0000",
        shadow: 0.81,
        highlight: 0.27
    },
    "module-back-inner":{
        color: "#ff9a00",
        shadow: 0.6,
        highlight: 0.49
    },
    "module-back-inner-stripes":{
        color: "#ffe808",
        shadow: 0.29,
        highlight: 0.8
    },
    "module-top":{
        color: "#ff0000",
        shadow: 0.81,
        highlight: 0.27
    },
    "module-top-blade":{
        color: "#ffe808",
        shadow: 0.29,
        highlight: 0.8
    },
    "module-top-triangle":{
        color: "#ffe808",
        shadow: 0.29,
        highlight: 0.8
    },
    "midsole":{
        color: "#ff0000",
        shadow: 0.81,
        highlight: 0.27
    },
    "midsole-blade":{
        color: "#ffe808",
        shadow: 0.29,
        highlight: 0.8
    },
    "midsole-module":{
        color: "#ff0000",
        shadow: 0.81,
        highlight: 0.27
    },
    "midsole-module-text":{
        color: "#ffe808",
        shadow: 0.29,
        highlight: 0.8
    },
    "outsole":{
        color: "#ff1313",
        shadow: 0.8,
        highlight: 0.28
    }
}

export const THEME_NATURE = {
    "background":{
        color: "#e1f4c3",
    },
    "upper-inner": {
        color: "#b5c99a",
        shadow: 0.51,
        highlight: 0.57
    },
    "lining":{
        color: "#87986a",
        shadow: 0.74,
        highlight: 0.34
    },
    "upper-outer":{
        color: "#97a97c",
        shadow: 0.67,
        highlight: 0.41
    },
    "upper-outer-frame":{
        color: "#87986a",
        shadow: 0.74,
        highlight: 0.34
    },
    "swoosh":{
        color: "#e9f5db",
        shadow: 0.21,
        highlight: 0.88
    },
    "swoosh-frame":{
        color: "#97a97c",
        shadow: 0.67,
        highlight: 0.41
    },
    "laces":{
        color: "#e9f5db",
        shadow: 0.21,
        highlight: 0.88
    },
    "module-back-outer":{
        color: "#718355",
        shadow: 0.81,
        highlight: 0.26
    },
    "module-back-inner":{
        color: "#97a97c",
        shadow: 0.67,
        highlight: 0.41
    },
    "module-back-inner-stripes":{
        color: "#e9f5db",
        shadow: 0.21,
        highlight: 0.88
    },
    "module-top":{
        color: "#718355",
        shadow: 0.81,
        highlight: 0.26
    },
    "module-top-blade":{
        color: "#e9f5db",
        shadow: 0.21,
        highlight: 0.88
    },
    "module-top-triangle":{
        color: "#e9f5db",
        shadow: 0.21,
        highlight: 0.88
    },
    "midsole":{
        color: "#718355",
        shadow: 0.81,
        highlight: 0.26
    },
    "midsole-blade":{
        color: "#e9f5db",
        shadow: 0.21,
        highlight: 0.88
    },
    "midsole-module":{
        color: "#718355",
        shadow: 0.81,
        highlight: 0.26
    },
    "midsole-module-text":{
        color: "#e9f5db",
        shadow: 0.21,
        highlight: 0.88
    },
    "outsole":{
        color: "#718355",
        shadow: 0.81,
        highlight: 0.26
    }
}

export const THEME_PINK_PANTHER = {
    "background":{
        color: "#ff96c9",
    },
    "upper-inner": {
        color: "#ff1472",
        shadow: 0.79,
        highlight: 0.29
    },
    "lining":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "upper-outer":{
        color: "#EA0085",
        shadow: 0.82,
        highlight: 0.25
    },
    "upper-outer-frame":{
        color: "#bc006b",
        shadow: 0.89,
        highlight: 0.19
    },
    "swoosh":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "swoosh-frame":{
        color: "#bc006b",
        shadow: 0.89,
        highlight: 0.19
    },
    "laces":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "module-back-outer":{
        color: "#EA0085",
        shadow: 0.82,
        highlight: 0.25
    },
    "module-back-inner":{
        color: "#EA0085",
        shadow: 0.82,
        highlight: 0.25
    },
    "module-back-inner-stripes":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "module-top":{
        color: "#EA0085",
        shadow: 0.82,
        highlight: 0.25
    },
    "module-top-blade":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "module-top-triangle":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "midsole":{
        color: "#EA0085",
        shadow: 0.82,
        highlight: 0.25
    },
    "midsole-blade":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "midsole-module":{
        color: "#EA0085",
        shadow: 0.82,
        highlight: 0.25
    },
    "midsole-module-text":{
        color: "#F4F401",
        shadow: 0.24,
        highlight: 0.85
    },
    "outsole":{
        color: "#EA0085",
        shadow: 0.82,
        highlight: 0.25
    }
}

export const THEME_VINTAGE = {
    "background":{
        color: "#ccc",
    },
    "upper-inner": {
        color: "#E2C7AA",
        shadow: 0.46,
        highlight: 0.63
    },
    "lining":{
        color: "#8F857B",
        shadow: 0.78,
        highlight: 0.30
    },
    "upper-outer":{
        color: "#e3d3c2",
        shadow: 0.40,
        highlight: 0.69
    },
    "upper-outer-frame":{
        color: "#B19A81",
        shadow: 0.69,
        highlight: 0.39
    },
    "swoosh":{
        color: "#F8F0E5",
        shadow: 0.21,
        highlight: 0.88
    },
    "swoosh-frame":{
        color: "#956B4B",
        shadow: 0.84,
        highlight: 0.24
    },
    "laces":{
        color: "#F8F0E5",
        shadow: 0.21,
        highlight: 0.88
    },
    "module-back-outer":{
        color: "#F8F0E5",
        shadow: 0.21,
        highlight: 0.88
    },
    "module-back-inner":{
        color: "#F8F0E5",
        shadow: 0.21,
        highlight: 0.88
    },
    "module-back-inner-stripes":{
        color: "#8F857B",
        shadow: 0.78,
        highlight: 0.30
    },
    "module-top":{
        color: "#F8F0E5",
        shadow: 0.21,
        highlight: 0.88
    },
    "module-top-blade":{
        color: "#956B4B",
        shadow: 0.84,
        highlight: 0.24
    },
    "module-top-triangle":{
        color: "#956B4B",
        shadow: 0.84,
        highlight: 0.24
    },
    "midsole":{
        color: "#e6dfd5",
        shadow: 0.33,
        highlight: 0.76
    },
    "midsole-blade":{
        color: "#956B4B",
        shadow: 0.84,
        highlight: 0.24
    },
    "midsole-module":{
        color: "#efefef",
        shadow: 0.22,
        highlight: 0.87
    },
    "midsole-module-text":{
        color: "#956B4B",
        shadow: 0.84,
        highlight: 0.24
    },
    "outsole":{
        color: "#E2C7AA",
        shadow: 0.46,
        highlight: 0.63
    }
}

export const THEME_BLANK = {
    "background":{
        color: "#f1f1f1"
    },
    "upper-inner":{
        color: "#fcfcfc",
        shadow: 0.12,
        highlight: 0.98
    },
    "lining":{
        color: "#fdfdfd",
        shadow: 0.12,
        highlight: 0.98
    },
    "upper-outer":{
        color: "#fcfcfc",
        shadow: 0.12,
        highlight: 0.98
    },
    "upper-outer-frame":{
        color: "#ffffff",
        shadow: 0.1,
        highlight: 1
    },
    "swoosh":{
        color: "#fcfcfc",
        shadow: 0.12,
        highlight: 0.98
    },
    "swoosh-frame":{
        color: "#ffffff",
        shadow: 0.1,
        highlight: 1
    },
    "laces":{
        color: "#ffffff",
        shadow: 0.1,
        highlight: 1
    },
    "module-back-outer":{
        color: "#ffffff",
        shadow: 0.1,
        highlight: 1
    },
    "module-back-inner":{
        color: "#ffffff",
        shadow: 0.1,
        highlight: 1
    },
    "module-back-inner-stripes":{
        color: "#ffffff",
        shadow: 0.1,
        highlight: 1
    },
    "module-top":{
        color: "#fcfcfc",
        shadow: 0.12,
        highlight: 0.98
    },
    "module-top-blade":{
        color: "#efefef",
        shadow: 0.22,
        highlight: 0.88
    },
    "module-top-triangle":{
        color: "#e0e0e0",
        shadow: 0.33,
        highlight: 0.77
    },
    "midsole":{
        color: "#fcfcfc",
        shadow: 0.12,
        highlight: 0.98
    },
    "midsole-blade":{
        color: "#f3f3f3",
        shadow: 0.2,
        highlight: 0.9
    },
    "midsole-module":{
        color: "#fdfdfd",
        shadow: 0.12,
        highlight: 0.98
    },
    "midsole-module-text":{
        color: "#f7f7f7",
        shadow: 0.16,
        highlight: 0.94
    },
    "outsole":{
        color: "#fdfdfd",
        shadow: 0.12,
        highlight: 0.98
    }
}

export const THEME_HUXLEY = {
    "background":{
        color: "#9e292a"
    },
    "upper-inner":{
        color: "#ac1923",
        shadow: 0.91,
        highlight: 0.17
    },
    "lining":{
        color: "#3e1e1d",
        shadow: 0.98,
        highlight: 0.1
    },
    "upper-outer":{
        color: "#9e292a",
        shadow: 0.92,
        highlight: 0.16
    },
    "upper-outer-frame":{
        color: "#3e1e1d",
        shadow: 0.98,
        highlight: 0.1
    },
    "swoosh":{
        color: "#da2b26",
        shadow: 0.85,
        highlight: 0.23
    },
    "swoosh-frame":{
        color: "#3e1e1d",
        shadow: 0.98,
        highlight: 0.1
    },
    "laces":{
        color: "#000000",
        shadow: 1,
        highlight: 0.08
    },
    "module-back-outer":{
        color: "#da2b26",
        shadow: 0.85,
        highlight: 0.23
    },
    "module-back-inner":{
        color: "#da2b26",
        shadow: 0.85,
        highlight: 0.23
    },
    "module-back-inner-stripes":{
        color: "#3e1e1d",
        shadow: 0.98,
        highlight: 0.1
    },
    "module-top":{
        color: "#da2b26",
        shadow: 0.85,
        highlight: 0.23
    },
    "module-top-blade":{
        color: "#000000",
        shadow: 1,
        highlight: 0.08
    },
    "module-top-triangle":{
        color: "#000000",
        shadow: 1,
        highlight: 0.08
    },
    "midsole":{
        color: "#da2b26",
        shadow: 0.85,
        highlight: 0.23
    },
    "midsole-blade":{
        color: "#000000",
        shadow: 1,
        highlight: 0.08
    },
    "midsole-module":{
        color: "#da2b26",
        shadow: 0.85,
        highlight: 0.23
    },
    "midsole-module-text":{
        color: "#000000",
        shadow: 1,
        highlight: 0.08
    },
    "outsole":{
        color: "#da2b26",
        shadow: 0.85,
        highlight: 0.23
    }
}

export const THEME_SPACEPOD = {
    "background":{
        "color":"#d6d6d6"
    },
    "upper-inner":{
        "color":"#b1b1b1",
        "shadow":0.61,
        "highlight":0.48
    },
    "lining":{
        "color":"#9f7bb3",
        "shadow":0.77,
        "highlight":0.3
    },
    "upper-outer":{
        "color":"#9b9b9b",
        "shadow":0.7,
        "highlight":0.38
    },
    "upper-outer-frame":{
        "color":"#46475b",
        "shadow":0.94,
        "highlight":0.14
    },
    "swoosh":{
        "color":"#f8000d",
        "shadow":0.82,
        "highlight":0.26
    },
    "swoosh-frame":{
        "color":"#e9e9e9",
        "shadow":0.26,
        "highlight":0.83
    },
    "laces":{
        "color":"#f8000d",
        "shadow":0.82,
        "highlight":0.26
    },
    "module-back-outer":{
        "color":"#f5f5f5",
        "shadow":0.18,
        "highlight":0.92
    },
    "module-back-inner":{
        "color":"#f2f2f2",
        "shadow":0.2,
        "highlight":0.9
    },
    "module-back-inner-stripes":{
        "color":"#46475b",
        "shadow":0.94,
        "highlight":0.14
    },
    "module-top":{
        "color":"#f5f5f5",
        "shadow":0.18,
        "highlight":0.92
    },
    "module-top-blade":{
        "color":"#46475b",
        "shadow":0.94,
        "highlight":0.14
    },
    "module-top-triangle":{
        "color":"#46475b",
        "shadow":0.94,
        "highlight":0.14
    },
    "midsole":{
        "color":"#f5f5f5",
        "shadow":0.18,
        "highlight":0.92
    },
    "midsole-blade":{
        "color":"#46475b",
        "shadow":0.94,
        "highlight":0.14
    },
    "midsole-module":{
        "color":"#eeeeee",
        "shadow":0.23,
        "highlight":0.87
    },
    "midsole-module-text":{
        "color":"#46475b",
        "shadow":0.94,
        "highlight":0.14
    },
    "outsole":{
        "color":"#46475b",
        "shadow":0.94,
        "highlight":0.14
    }
}