import './style/fonts.css'
import './style/reset.css'
import './style/base.css'
import './style/tailwind.css'
import './style/kicks.css'
import './style/media-queries.css'
import React, { useState, useEffect } from "react"
import { useMotionAnimate } from 'motion-hooks'
import { stagger } from 'motion'

import { ChevronDownIcon } from '@heroicons/react/outline'
import { ColorSwatchIcon, PhotographIcon, InformationCircleIcon, UploadIcon, CollectionIcon } from '@heroicons/react/solid'
import { saveSvgAsPng } from 'save-svg-as-png-3'
import { Popover, RadioGroup, Transition } from '@headlessui/react'
import { HexColorPicker, HexColorInput } from "react-colorful"
import { scroller } from 'react-scroll'

// import { ReactComponent as EyeBubbleIcon } from './assets/svg/icon-eyebubble.svg'
// import { ReactComponent as HeartIcon } from './assets/svg/icon-heart.svg'
// import { ReactComponent as SwordsIcon } from './assets/svg/icon-swords.svg'
import { ReactComponent as GlobeIcon } from './assets/svg/icon-globe.svg'
import { ReactComponent as ShoeIcon } from './assets/svg/icon-shoe.svg'
import { ReactComponent as VialIcon } from './assets/svg/icon-vial.svg'
import { ReactComponent as RandomIcon } from './assets/svg/icon-random.svg'

import KicksCanvas from './components/KicksCanvas'
import KicksSampleSelect from './components/KicksSampleSelect'
import KicksLayerSelect from './components/KicksLayerSelect'
import KicksLayerSelectItem from './components/KicksLayerSelectItem'
import { deepClone } from './utils'

import {
    THEME_LIGHT,
} from './config/kicks-sample-themes'

const Color = require('color')
const lerp = (x, y, a) => x * (1 - a) + y * a

export const App = () => {
    const [styleConfig, setStyleConfig] = useState(THEME_LIGHT)
    const [styleNavTab, setStyleNavTab] = useState('configurator')
    const [activeLayerKey, setActiveLayerKey] = useState('upper-inner')

    const setLayerKey = key => {
        scroller.scrollTo(`scroll-target--${key}`, {
            horizontal: true,
            duration: 400,
            smooth: true,
            offset: -24,
            containerId: 'KicksLayerSelectScrollContainer',
            // offset: 50, // Scrolls to element + 50 pixels down the page
        })
        setActiveLayerKey(key)
    }

    const headerAnimation = useMotionAnimate(
        '.app-header',
        { y: [-100, 0], opacity: [0, 1] },
        {
            delay: .1,
            duration: 0.3,
            easing: [0.22, 0.03, 0.26, 1],
        },
    )
    const canvasAnimation = useMotionAnimate(
        '.kicks-canvas-wrapper',
        { opacity: [0, 1] },
        {
            duration: .5,
            easing: [0.22, 0.03, 0.26, 1],
        },
    )

    const canvasTextureAnimation = useMotionAnimate(
        'g.shadow, g.highlight',
        { opacity: [0, 1] },
        {
            delay: .1,
            duration: .5,
            easing: [0.22, 0.03, 0.26, 1],
        },
    )

    const configuratorAnimation = useMotionAnimate(
        '.kicks-style-navigation',
        { y: [400, 0], opacity: [0, 1] },
        {
            delay: .15,
            duration: 0.3,
            easing: [0.22, 0.03, 0.26, 1],
        },
    )

    const layerSelectItemsAnimation = useMotionAnimate(
        '.layer-select-item',
        { x: [50, 0], opacity: [0, 1] },
        {
            delay: stagger(0.05, { start: 0.4 }),
            duration: 0.2,
            easing: [0.22, 0.03, 0.26, 1],
        },
    )

    const colorPickerAnimation = useMotionAnimate(
        '.kicks-color-picker',
        { y: [100, 0], opacity: [0, 1] },
        {
            delay: .4,
            duration: 0.3,
            easing: [0.22, 0.03, 0.26, 1],
        },
    )

    const onColorChange = color => {
        const colorObject = Color(color)
        const luminosity = colorObject.luminosity()
        const newShadow = lerp(0.1, 1, 1 - luminosity)
        const newHighlight = lerp(0.08, 1, luminosity)

        const newStyleConfig = deepClone(styleConfig)

        newStyleConfig[activeLayerKey].color = color

        if (activeLayerKey !== 'background') {
            newStyleConfig[activeLayerKey].shadow = newShadow
            newStyleConfig[activeLayerKey].highlight = newHighlight
        }

        setStyleConfig(newStyleConfig)
    }

    const toDataURL = src => new Promise((resolve, reject) => {
        let img = new Image()
        img.crossOrigin = 'Anonymous'
        img.onload = function(event) {
            console.log(event)
            let canvas = document.createElement('CANVAS')
            let ctx = canvas.getContext('2d')
            let dataURL
            canvas.height = this.naturalHeight
            canvas.width = this.naturalWidth
            ctx.drawImage(this, 0, 0)
            dataURL = canvas.toDataURL()
            resolve(dataURL)
            canvas = null
        }
        img.src = src
    })

    //   toDataURL(
    //     'https://www.gravatar.com/avatar/d50c83cc0c6523b4d3f6085295c953e0',
    //     function(dataUrl) {
    //       console.log('RESULT:', dataUrl)
    //     }
    //   )

    // const onImageChange = url => {
    //     const newStyleConfig = deepClone(styleConfig)

    //     toDataURL(url)
    //         .then(base64 => {
    //             console.log(base64)
    //             newStyleConfig[activeLayerKey].image = url

    //             setStyleConfig(newStyleConfig)
    //         })
    //         .catch(console.log)

    // }

    const randomizeColors = () => {
        const newStyleConfig = deepClone(styleConfig)

        Object.keys(styleConfig).forEach(layerKey => {
            const newColor = Color.rgb(Math.random() * 255, Math.random() * 255, Math.random() * 255)
            const luminosity = newColor.luminosity()
            const newShadow = lerp(0.1, 1, 1 - luminosity)
            const newHighlight = lerp(0.08, 1, luminosity)
            newStyleConfig[layerKey] = {
                ...styleConfig[layerKey],
                color: newColor.hex(),
                image: null,
            }
            if (layerKey !== 'background') {
                newStyleConfig[layerKey].shadow = newShadow
                newStyleConfig[layerKey].highlight = newHighlight
            }
        })

        setStyleConfig(newStyleConfig)
    }

    const downloadAsPNG = () => {
        saveSvgAsPng(document.querySelector(".kicks-svg"), "demokicks.png", { encoderOptions: 1 })
    }

    const downloadAsJSON = () => {
        const element = document.createElement('a')
        const textFile = new Blob([ JSON.stringify(styleConfig) ], { type: 'application/json' })
        element.href = URL.createObjectURL(textFile)
        element.download = 'theme-config.json'
        document.body.appendChild(element)
        element.click()
    }

    const onThemeFileUploadFileSelect = event => {
        console.log(event)
        const fileReader = new FileReader()
        fileReader.onload = e => {
            console.log(JSON.parse(e.target.result))
            if (isValidJSON(e.target.result)) {
                setStyleConfig(JSON.parse(e.target.result))
            }
        }
        fileReader.readAsText(event.target.files[0], 'UTF-8')
    }

    const isValidJSON = input => {
        try {
            JSON.parse(input)
        } catch (e) {
            return false
        }
        return true
    }

    useEffect(() => {
        canvasAnimation.play()
        headerAnimation.play()
        configuratorAnimation.play()
        layerSelectItemsAnimation.play()
        colorPickerAnimation.play()
        canvasTextureAnimation.play()

        const touchStartListener = document.addEventListener('touchstart', event => {
            document.body.classList.remove('no-touch')
            document.removeEventListener('touchstart', touchStartListener)
        })

        return () => {
            if (touchStartListener) {
                document.removeEventListener('touchstart', touchStartListener)
            }
        }
    }, [])

    return (
        <div className="w-full min-h-full flex flex-col" style={{ backgroundColor: '#ccc' }}>
            <div className='app-header flex-none relative w-full bg-secondary-700 px-6 h-12 md:h-14 z-30 flex items-center justify-between'>
                <div className='flex items-center'>
                    <Popover className="relative">
                        <Popover.Button className='py-1'>
                            <h1 className='font-black inline-block text-md text-neutral-300 mr-2'>demokicks <span className='text-xs text-neutral-500 font-normal'>v0.1.1</span></h1>
                            <InformationCircleIcon className="h-5 w-5 text-neutral-400 inline-block align-text-bottom" />
                        </Popover.Button>
                        <Popover.Panel className="absolute z-10 top-12">
                            <div className="relative w-80 sm:w-96 bg-secondary-800 p-6 rounded-lg">
                                <p className='text-neutral-300 max-w-sm'>
                                    Inofficial tool to test different color combinations for RTFKT x Nike Dunk Genesis CRYPTOKICKS. For established and aspiring RTFKT Creators.
                                </p>
                                <div className='mt-3'>
                                    {/* <EyeBubbleIcon className='inline-block mr-2 w-5 h-5 fill-neutral-50' />
                                    <HeartIcon className='inline-block mr-2 w-5 h-5 fill-neutral-50' />
                                    <SwordsIcon className='inline-block mr-4 w-5 h-5 fill-neutral-50' /> */}
                                    <GlobeIcon className='inline-block mr-2 w-5 h-5 fill-neutral-50' />
                                    <ShoeIcon className='inline-block mr-2 w-5 h-5 fill-neutral-50' />
                                    <VialIcon className='inline-block mr-2 w-5 h-5 fill-neutral-50' />
                                </div>
                                <p className='text-neutral-300 max-w-sm mt-6 font-light'>
                                    Work in progress. Found bugs or have other feedback? DM me on Twitter:
                                </p>
                                <a className="no-underline flex items-center mt-2 rounded-full overflow-hidden bg-secondary-700 hover:bg-secondary-600" href="https://twitter.com/wavedasheth" rel="noreferrer" target="_blank">
                                    <img className='flex-none h-12 w-12 rounded-full overflow-hidden' src="https://pbs.twimg.com/profile_images/1526531847529938944/KuqnRMYM_400x400.jpg" alt="wavedash.eth" />
                                    <div className='flex-1 flex items-center justify-between px-4'>
                                        <span className='text-neutral-300 font-bold'>wavedash.eth</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" focusable="false" role="none">
                                            <path fill="#ccc" d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path>
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </Popover.Panel>
                    </Popover>
                </div>
                <Popover className="relative flex items-center">
                    {/* <button className='bg-secondary-600 px-3 py-1 rounded-md mr-2' onClick={share}>
                        <span className='inline-block text-neutral-300 text-xs uppercase mr-2'>Share</span>
                        <ShareIcon className='inline-block h-3 w-3 text-neutral-300'/>
                    </button> */}
                    <Popover.Button className='bg-secondary-600 px-3 py-1 rounded-md'>
                        <span className='inline-block text-neutral-300 text-xs uppercase mr-2'>Export</span>
                        <ChevronDownIcon className="inline-block h-3 w-3 text-neutral-300" />
                    </Popover.Button>
                    <Popover.Panel className="absolute z-10 top-12 right-0">
                        <div className="relative w-72 bg-secondary-800 rounded-lg overflow-hidden p-6">
                            <button className='text-neutral-200 text-sm uppercase bg-secondary-700 hover:bg-secondary-600 py-3 px-4 text-left rounded-lg' onClick={downloadAsPNG}>
                                <PhotographIcon className="h-4 w-4 text-neutral-300 inline-block mr-2" />
                                <span className=''>Image (PNG)</span>
                            </button>
                            <button className='mt-3 text-neutral-200 text-sm uppercase bg-secondary-700 hover:bg-secondary-600 py-3 px-4 text-left rounded-lg' onClick={downloadAsJSON}>
                                <ColorSwatchIcon className="h-4 w-4 text-neutral-300 inline-block mr-2" />
                                <span className=''>Export Theme Config (JSON)</span>
                            </button>
                            <button className='mt-3 text-neutral-200 text-sm uppercase bg-secondary-700 hover:bg-secondary-600 py-3 px-4 text-left rounded-lg'>
                                <UploadIcon className="h-4 w-4 text-neutral-300 inline-block mr-2" />
                                <label htmlFor="file-upload" className="cursor-pointer custom-file-upload">
                                    Import Theme Config (JSON)
                                </label>
                                <div className='w-px h-px overflow-hidden opacity-0'>
                                    <input
                                        id='file-upload'
                                        type='file'
                                        accept='application/json'
                                        onChange={onThemeFileUploadFileSelect}
                                    />
                                </div>
                            </button>
                        </div>
                    </Popover.Panel>
                </Popover>
            </div>
            <div
                style={{
                    backgroundColor: styleConfig['background'].color,
                }}
                className='app-main relative flex-grow flex flex-col'>

                <div className='flex-none'>
                    <div className="kicks-canvas-wrapper">
                        <KicksCanvas
                            config={styleConfig}
                            onSelect={key => {
                                if (styleNavTab === 'samples') {
                                    setStyleNavTab('configurator')
                                }
                                setLayerKey(key)
                            }}
                        />
                    </div>
                </div>
                <div className="w-full flex-1 flex flex-col">
                    <div className='kicks-style-navigation bg-secondary-700 flex-1'>
                        <div className='w-full px-6 h-16 flex items-center justify-between'>
                            <RadioGroup
                                value={styleNavTab}
                                onChange={setStyleNavTab}
                                className="inline-flex rounded-lg overflow-hidden">

                                {[{
                                    key: 'configurator',
                                    name: 'Customize',
                                    icon: ColorSwatchIcon,
                                },
                                {
                                    key: 'samples',
                                    name: 'Examples',
                                    icon: CollectionIcon,
                                }].map(option => (
                                    <RadioGroup.Option
                                        key={option.key}
                                        value={option.key}>

                                        {({ checked }) => (
                                            <div className={`cursor-pointer flex items-center mr-6`}>
                                                <option.icon className={`h-4 w-4 mr-2 ${checked ? 'text-neutral-50' : 'text-neutral-500'}`} />
                                                <span className={`font-bold inline-block align-text-top ${checked ? 'text-neutral-50' : 'text-neutral-500'}`}>{option.name}</span>
                                            </div>
                                        )}
                                    </RadioGroup.Option>
                                ))}
                            </RadioGroup>
                            <div className='flex items-center'>
                                <button className='bg-secondary-600 px-3 py-1 rounded-md' onClick={randomizeColors}>
                                    <span className='text-neutral-300 text-xs uppercase hidden xs:inline-block mr-2'>Randomize</span>
                                    <RandomIcon className='inline-block h-3 w-3 fill-neutral-300'/>
                                </button>
                            </div>
                        </div>
                        <div>
                            <Transition
                                show={styleNavTab === 'configurator'}
                                enter="transition-opacity duration-75"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="transition-opacity duration-150"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0">

                                <KicksLayerSelect>
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'upper-inner'}
                                        layerName={'Upper Inner'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'upper-outer'}
                                        layerName={'Upper Outer'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'upper-outer-frame'}
                                        layerName={'Upper Outer Frame'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'lining'}
                                        layerName={'Lining'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'laces'}
                                        layerName={'Laces'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'swoosh'}
                                        layerName={'Swoosh'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'swoosh-frame'}
                                        layerName={'Swoosh Frame'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'midsole'}
                                        layerName={'Midsole'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'midsole-blade'}
                                        layerName={'Midsole Blade'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'midsole-module'}
                                        layerName={'Midsole Module'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'midsole-module-text'}
                                        layerName={'Midsole Module Text'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'outsole'}
                                        layerName={'Outsole'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'module-top'}
                                        layerName={'Module Top'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'module-top-blade'}
                                        layerName={'Module Top Blade'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'module-top-triangle'}
                                        layerName={'Module Top Triangle'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'module-back-outer'}
                                        layerName={'Module Back Outer'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'module-back-inner'}
                                        layerName={'Module Back Inner'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'module-back-inner-stripes'}
                                        layerName={'Module Back Inner Stripes'}
                                    />
                                    <KicksLayerSelectItem
                                        onSelect={setLayerKey}
                                        activeKey={activeLayerKey}
                                        layerKey={'background'}
                                        layerName={'Background'}
                                    />
                                </KicksLayerSelect>
                                <div className='kicks-color-picker p-6'>
                                    <HexColorPicker
                                        className='w-auto'
                                        color={styleConfig[activeLayerKey].color}
                                        onChange={onColorChange}
                                    />
                                    <HexColorInput
                                        className='bg-lighten text-neutral-200 h-10 py-2 px-3 inline w-full rounded-b-lg'
                                        color={styleConfig[activeLayerKey].color}
                                        onChange={onColorChange}
                                    />
                                    {/* <p className='block mt-6 text-neutral-300'>Image Overlay</p>
                                    <input
                                        className="mt-2 appearance-none flex-1 bg-neutral-800 rounded-lg py-2 px-3 text-neutral-300 w-full"
                                        value={styleConfig[activeLayerKey].image || ''}
                                        placeholder="Image URL"
                                        onChange={(event) => {
                                            const url = event.target.value

                                            if (styleConfig[activeLayerKey].image !== url) {
                                                onImageChange(url)
                                            }
                                        }}
                                    />
                                    <div className='flex items-center mt-3'>
                                        <ExclamationIcon className="flex-none h-5 w-5 text-orange-300 mr-2" />
                                        <p className='flex-1 text-neutral-300 text-sm'>Image export doesn't work properly if overlays are added. You can take a screenshot instead.</p>
                                    </div>
                                    <p className='block mt-2 text-neutral-500 text-sm max-w-xl'>
                                        Experimental feature. Supports image via URL. Custom upload and image cropping/positioning might be added later. Works best with images where width >= 800px and height >= 500px (approx.) to fill the available space. Try different colors above to fine-tune shadows and highlights.
                                        Doesn't support transparency and animations (e.g. GIFs are rendered as a static image).
                                    </p> */}
                                </div>
                            </Transition>
                            <Transition
                                show={styleNavTab === 'samples'}
                                enter="transition-transform duration-75"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition-transform duration-150"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full">

                                <KicksSampleSelect
                                    onStyleChange={setStyleConfig}
                                />
                            </Transition>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
