import React, { useEffect } from "react"

const KicksLayerSelect = props => {
    return (
        <ul
            id="KicksLayerSelectScrollContainer"
            className="list-style-none overflow-x-auto whitespace-nowrap overflow-scrolling px-6">

            {props.children}
        </ul>
    )
}

export default KicksLayerSelect

